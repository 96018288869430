import React from 'react'
import Discord from '../../images/discord.png'

const Footer = () => {
  return (
    <div className='font-medium'>
      <div className='bg-[#CD2C35] h-[3px] w-full' />

      <div className='flex justify-between px-10 py-5'>
        <div className=''>
          <p className='flex gap-1'>© 2023 <span className='text-[#CD2C35] font-bold'>|</span> Air India Virtual</p>
          <p>Air India Virtual is NOT affiliated in any way with the real world Air India</p>
        </div>

        <div className='flex items-center space-x-4 hover:scale-[1.07]'>
          <p>Follow Us</p>
          <a href="https://discord.gg/PaGh87ye" target='_blank'><img draggable='false' src={Discord} alt="Discord" /></a>

        </div>
      </div>
      
    </div>
  )
}

export default Footer