import React from 'react'

import Banner1 from '../../images/AIC 1.png'
import Banner2 from '../../images/dominik-schroder-FIKD9t5_5zQ-unsplash 1.png'
import Banner3 from '../../images/A 2 1.png'

import Aeroplane3 from '../../images/A 3.png'
import Aeroplane4 from '../../images/A 4.png'

import Event from '../../images/icons/events.png'
import ACAR from '../../images/icons/acar.png'
import Community from '../../images/icons/community.png'

// import VACC from '../../images/patners/vacc.png'
import Bharatiya from '../../images/patners/bharatiya.png'
// import VATSIM from '../../images/patners/vatsim.png'
import Indigo from '../../images/patners/indigo.png'
// import IVAO from '../../images/patners/ivao.png'
// import MNA from '../../images/patners/mynextairline.png'

import aic from '../../images/patners/image 17.png'

import aa from '../../images/patners/image 18.png'

import vti from '../../images/patners/image 19.png'

import aira from '../../images/patners/image 20.png'

import aae from '../../images/patners/image 21.png'


const Home = () => {
  return (
    <div className=''>
      {/* Banner1 */}
      <div className="relative">
        <img src={Banner1} draggable='false' alt="Bannerimage1" className="w-full" />
        <div className="absolute -top-[20%] inset-0 flex flex-col items-center justify-center bg-transparent font-medium text-[32px] text-white p-4">
          <div className='text-center -space-y-3'>
            <p>WELCOME TO</p>
            <span className='font-bold text-[60px] block'>AIR INDIA VIRTUAL</span>
            <p>YOUR PALACE IN THE SKY</p>
          </div>
          <a href="https://vamsys.io/register/aic" className='bg-white text-[#D9000D] text-[16px] text-center font-bold px-10 py-2 my-8 hover:scale-[1.07]'>Join Us Now</a>
        </div>
      </div>

      {/* Banner2 */}
      <div className="relative">
        <img src={Banner2} draggable='false' alt="Bannerimage2" className="w-full" />
        <div className="absolute -top-[20%] inset-0 flex flex-col items-center justify-center bg-transparent font-medium text-[32px] text-black p-4">
          
          <div className='text-center'>
            <p className='text-[42px] font-[800]'>Airlines we offer</p>
            
            <div className='relative flex space-x-24 top-10'>
              <div className='relative'>


              </div>
              <div className='grid grid-cols-3 justify-center items-center gap-5  mx-auto w-[90%]'>
            <img  className='col-start-1'src={aic} alt="patners" draggable='false'/>
            <img className='col-start-2'src={aa} alt="patners" draggable='false'/> 
            <img className='col-start-3'src={vti} alt="patners" draggable='false'/> 
            {/* <img src={Indigo} alt="patners" draggable='false'/> */}
            <img  className='col-start-4'src={aira} alt="patners" draggable='false'/> 
            <img  className='col-start-5'src={aae} alt="patners" draggable='false'/>
          </div>
            
              
              {/* <div className='relative'>
                <p className='font-[900] text-[32px]'>3280+</p>
                <p className='font-medium text-[16px]'>Routes</p>
              </div>
              <div>
                <p className='font-[900] text-[32px]'>2</p>
                <p className='font-medium text-[16px]'>World Tours</p>
              </div>
              <div>
                <p className='font-[900] text-[32px]'>36,484+</p>
                <p className='font-medium text-[16px]'>Flight Hours</p>
              </div>
              <div>
                <p className='font-[900] text-[32px]'>3483</p>
                <p className='font-medium text-[16px]'>Pireps</p>
              </div>
              <div>
                <p className='font-[900] text-[32px]'>8</p>
                <p className='font-medium text-[16px]'>Aircrafts</p>
              </div> */}
              
            </div>
          </div>
        </div>
      </div>

      {/* Banner3 */}
      <div className='text-center space-y-10 py-10'>
          <p className='text-[42px] font-[800]'>Why Fly with Us?</p>

          <div className='grid grid-cols-3 gap-5 px-10'>
            <div class="bg-white rounded-lg shadow-2xl flex flex-col items-center px-8 py-20 hover:scale-[1.07]">
              <div class="flex items-center justify-center h-12 w-12 rounded-full bg-red-500 text-white p-3">
                <img draggable='false' src={Event} alt="events" />
              </div>
              <div class="mt-4">
                <h2 class="text-[22px] font-semibold text-gray-800">Regular Events</h2>
                <p class="mt-2 text-gray-600">
                We host a variety of internal and public events for our pilots and the wider community to enjoy.
                 Our events provide an excellent opportunity for both serious and casual pilots to fly undiscovered routes, 
                 explore more and gain flight hours.
                </p>
              </div>
            </div>
 
            <div class="bg-[#CD2C35] rounded-lg shadow-2xl flex flex-col items-center px-8 py-20 hover:scale-[1.07]">
              <div class="flex items-center justify-center h-12 w-12 rounded-full bg-white  p-3">
                <img draggable='false' src={ACAR} alt="events" />
              </div>
              <div class="mt-4">
                <h2 class="text-[22px] font-semibold text-white">ACARS & PIREPS</h2>
                <p class="mt-2 text-white">
                We offer the best Flight Booking and most sophisticated ACARS System among VA’s. 
                We use real world procedures in our virtual airline to make your flying more realistic. On 
                our platform you will experience a smooth and fast PIREP filing process
                </p>
              </div>
            </div>
            <div class="bg-white rounded-lg shadow-2xl flex flex-col items-center px-8 py-20 hover:scale-[1.07]">
              <div class="flex items-center justify-center h-12 w-12 rounded-full bg-red-500 text-white p-3 ">
                <img draggable='false' src={Community} alt="events" />
              </div>
              <div class="mt-4">
                <h2 class="text-[22px] font-semibold text-gray-800">Lively Community</h2>
                <p class="mt-2 text-gray-600">
                We have one of the most vibrant community among VA’s. We are connecting diversities 
                among aviation enthusiasts. Plan a group flight with other pilots, Do regular events ,ask 
                for support or just chat with others on our discord dedicated server.
                </p>
              </div>
            </div>
          </div>
      </div>

      {/* Banner4 */}
      <div className="relative">
        <img src={Banner3} draggable='false' alt="Bannerimage3" className="w-full" />
        <div className="absolute inset-0 flex flex-col bg-transparent font-medium text-[32px] text-white p-10">
          <div className='text-center text-black py-10'>
            <p className='text-[42px] font-[800]'>Our Partners</p>
          </div>

          <div className='grid grid-cols-2 justify-center items-center gap-2  mx-auto w-[75%]'>
            {/* <img src={VACC} alt="patners" draggable='false'/> */}
            <img  src={Bharatiya} alt="patners" draggable='false'/>
            {/* <img src={VATSIM} alt="patners" draggable='false'/> */}
            
            {/* <img className='col-start-2' src={IVAO} alt="patners" draggable='false'/> */}
            {/* <img src={MNA} alt="patners" draggable='false'/> */}


            <img  className = 'col-start-3'src={Indigo} alt="patners" draggable='false'/>

          </div>
        </div>
      </div>

      {/* Banner5 */}
      <div className='bg-[#CD2C35] p-10 flex justify-around'>
        {/* <div>
          <iframe className='rounded-2xl' width="560" height="315" src="https://www.youtube.com/embed/fO4Aj6JSahc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div> */}

        <div>
          

          <div className='text-center text-white text-sm space-y-3 '>
            <p className='text-[45px] font-[700] bold  '>We Support</p>
            <img src={Aeroplane3} alt="Aeroplane" draggable='false' class = "center"  height={4000} width={750} />
            <p className='text-[30px] font-[600] bold '> Prepar3D | Microsoft Flight Simulator | X-Plane  </p>
            {/* <p className='text-[24px] font-[700] bold '></p>
            <p className='text-[24px] font-[700] bold '></p> */}
          </div>
        </div>
      </div>

      {/* Banner6  */}
      <div className='p-20 text-center text-[20px] font-[500] space-y-8' id='aboutus'>
        <p className='text-[42px] font-[800]'>About Air India Virtual</p>

         <p>
         Founded in April 2023, Air India Virtual aspires to provide an immersive flight simulation experience with Air India, India's national pride! Air India virtual aims to create a close to reality, yet a delightful and relaxing experience for our pilots. Our vision, is to create a world-wide appeal for Air India - India's pride, by creating a warm & welcoming community of virtual aviation enthusiasts and to create a fun-filled bonding element with different events, rewards and group flights.
        </p> 

        {/* <p>We are a group of flight simulator enthusiasts spread across the globe using various simulators like MSFS P3D FSX and X-plane.</p> */}
      </div>

      {/* Banner7  */}
      <div className=' bg-[#CD2C35] flex flex-col items-center text-white mb-4 '>
        <div className='flex items-center space-x-10 '>
          <p className='text-[32px] font-bold'>Ready to Fly with Us?</p>
          <a href="https://vamsys.io/register/aic" className='bg-#CD2C35 border-2 border-white text-[16px] text-center font-bold px-10 py-2 my-8 hover:scale-[1.07]'>Join Us Now</a>
        </div>
        <img className='pb-20' src={Aeroplane4} alt="aeroplane" height='154px' width='665px' draggable='false'/>

        <h1 className='text-[50px] font-[900] -my-5'>EXPERIENCE THE LIFE OF A REAL-WORLD PILOT</h1>
      </div>
    </div>
  )
}

export default Home