import React from 'react'
import Logo from '../../images/logo.png'

const Navbar = () => {
  return (
    <div className='py-[25px] flex justify-between shadow-2xl px-16'>
      <a href="/" className='z-10'>
        <img draggable='false' src={Logo} alt="logo" width='250px'/>
      </a>

        <div className='flex gap-10 items-center z-10'>
        <ul className='flex gap-6 font-semibold text-[14px]'>
          <li className='hover:bg-[#D9000D] hover:text-white px-4 py-2 font-semibold'><a href="/">Home</a></li>
          <li className='hover:bg-[#D9000D] hover:text-white px-4 py-2 font-semibold'><a href="https://vamsys.io/map">Live Flights</a></li>
          <li className='hover:bg-[#D9000D] hover:text-white px-4 py-2 font-semibold'><a href="/">Fleet</a></li>
          <li className='hover:bg-[#D9000D] hover:text-white px-4 py-2 font-semibold'><a href="#aboutus">About Us</a></li>
          {/* <li className='hover:bg-[#D9000D] hover:text-white px-4 py-2'><a href="/">Join Us</a></li> */}
          {/* <li className='bg-[#D9000D] text-white text-center-left px-4 py-2 text-[14px] font-semibold'><a href="https://vamsys.io/register/aic">Join Us</a></li>  */}
        </ul>

        <a href="https://vamsys.io/login/aic" className='over:bg-[#D9000D] px-4 py-2 font-semibold'>Log In</a>
        <a href="https://vamsys.io/register/aic" className='bg-[#D9000D] text-white text-center-left px-4 py-2 text-[14px] font-semibold'>Join Us</a>
      </div>

    </div>
  )
}

export default Navbar