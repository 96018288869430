import React from 'react'

import Logo from '../../images/logo.png'
import Aeroplane from '../../images/Aeroplane.png'

const Login = () => {
  return (
    <div className='grid grid-cols-2 h-screen justify-items-center items-center'>
      <div>
        <img draggable='false' src={Logo} alt="logo" width='500px'/>

        <p className='text-center font-semibold text-[25px] text-gray-600 my-10'>Sign In To Pilot Area</p>

        <form className='flex flex-col space-y-4'>
          <input className='border-b-2 outline-none p-2' type="text" placeholder='Email or Pilot ID'/>
          <input className='border-b-2 outline-none p-2' type="password" placeholder='Password'/>

          <div className='flex justify-between'>
            <div className='flex space-x-2 items-center cursor-pointer'>
              <input type="checkbox" name="rememberme" id="rememberme" className='h-4 w-4'/>
              <p>Remember me</p>
            </div>

            <a href="/" className='text-gray-500 font-semibold hover:underline'>Forgotten Password</a>
          </div>

          <input type="submit" className='bg-[#D9000D] text-white w-full py-3' value='Login'/>
        </form>

        <div  className='mt-20 space-y-5'>
          <div className='flex justify-center space-x-5'>
            <p>Don't Have an account yet?</p> <a href="/register" className='text-gray-500 font-semibold hover:underline'>Join Air India Virtual</a>
          </div>

          <div className='flex justify-center'>
            <p className='flex gap-1'>© 2023 <span className='text-[#CD2C35] font-bold'>|</span> Air India Virtual</p>
          </div>
        </div>
      </div>

      <img className='h-full' src={Aeroplane} alt="aeroplane" draggable='false'/>
    </div>
  )
}

export default Login