import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Navbar, Footer, Home, Login } from './components';

const App = () => {
  const currentUrl = window.location.href;
  return (
    <>
      <Router >
        {/* { currentUrl!== 'http://localhost:3000/login' ? <Navbar /> : '' } */}
        { currentUrl!== 'https://vamsys.io/login/aic' ? <Navbar /> : '' }
        <Routes>
          <Route index element={<Home />}/>
          <Route path='login' element={<Login />}/>

          <Route path="*" element={<p>There's nothing here: 404!</p>} />
        </Routes>

        { currentUrl!== 'https://vamsys.io/login/aic' ? <Footer /> : '' }
      </Router>
    </>
  )
}

export default App